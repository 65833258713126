<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <!--搜索-->
                    <el-input placeholder="请输入权限名称" v-model="queryInfo.name" clearable @clear="queryAuthorityList"> 
                        <el-button slot="append" icon="el-icon-search" @click="queryAuthorityList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="ShowAddAuthoriryDialog">添加权限</el-button>
                </el-col>
            </el-row>
            <div class="height_div">
                <tree-table class="treetable" 
                    :data="authoritylist"  
                    :columns="columns" 
                    :show-row-hover="false"   
                    border show-index index-text="#" 
                    :children-prop="treechildren"
                    :expand-type="false" 
                    :selection-type="false" 
                    treeType v-loading="Loading">
                    <template slot="order" slot-scope="scope">
                        <el-tag v-if="scope.row.parentID === 0">一级</el-tag>
                        <el-tag type="success" v-else-if="scope.row.parentID !== 0">二级</el-tag>
                    </template>
                    <template slot="option" slot-scope="scope">
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-edit" @click="ShowModifyAuthority(scope.row)"></el-button>
                        </el-tooltip>
                            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                                <el-button type="danger" icon="el-icon-delete" @click="DeleteAuthority(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </tree-table>
            </div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.start"
                :page-sizes="spreadCount"
                :page-size="queryInfo.count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="authoritytotal">
            </el-pagination>
           
        </el-card>

        <el-dialog title="添加权限" :visible.sync="addAuthoriryDialogVisible" width="30%"  @close="AddAuthoriryDialogClosed" :close-on-click-modal="false">
            <el-form v-loading="AdLoading" :model="addAuthoriryForm" :rules="addAuthoriryFormRules" ref="addAuthoriryFormRef"  label-width="100px">
                <el-form-item label="权限名称：" prop="Name">
                    <el-input v-model="addAuthoriryForm.Name"></el-input>
                </el-form-item>
                <el-form-item label="链接：" prop="Abbreviation">
                    <el-input v-model="addAuthoriryForm.Abbreviation"></el-input>
                </el-form-item>
                <el-form-item label="父类权限：">
                    <el-select style="display:table" width="30" v-model="parentAuthorityIDvalue" placeholder="请选择父类权限" >
                        <el-option
                        v-for="item in parentAuthorityList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                            <span style="float: left">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addAuthoriryDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="AddAuhority" :disabled="AdLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑权限" :visible.sync="editAuthoriryDialogVisible" width="30%" @close="EditAuthoriryDialogClosed" :close-on-click-modal="false">
            <el-form v-loading="EdLoading" :model="editAuthorityForm" :rules="editAuthorityFormRules" ref="editAuthoriryFormRef" label-width="100px">
                <el-form-item label="权限名称：" prop="name">
                    <el-input v-model="editAuthorityForm.name"></el-input>
                </el-form-item>
                <el-form-item label="链接：" prop="abbreviation">
                    <el-input v-model="editAuthorityForm.abbreviation"></el-input>
                </el-form-item>
                <el-form-item label="父类权限：">
                    <el-select style="display:table" width="30" v-model="parentAuthorityIDvalue" placeholder="请选择父类权限" >
                        <el-option
                        v-for="item in parentAuthorityList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                            <span style="float: left">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editAuthoriryDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyAuthority" :disabled="EdLoading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import TreeTable from 'vue-table-with-tree-grid'
import { SpreadCount } from '@/assets/js/systemvariable.js'
Vue.component('tree-table',TreeTable)
export default {
    data(){
        return {
            columns:[
                {
                    label:'名称',
                    prop:'name'
                },
                {
                    label:'链接',
                    prop:'abbreviation'
                },
                {
                    label:'等级',
                    type:'template',
                    template:'order'
                },
                {
                    label:'操作',
                    type:'template',
                    template:'option'
                }
            ],
            treechildren:'authorities',
            authoritylist:[],
            queryInfo:{
                name:'',
                start:0,
                count:SpreadCount[0]
            },
            authoritytotal:0,
            addAuthoriryDialogVisible:false,
            addAuthoriryForm:{
                Name:'',
                Abbreviation:'',
                ParentID:0,
            },
            addAuthoriryFormRules:{
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                Abbreviation: [
                    { required: true, message: '请输入链接', trigger: 'blur' }
                ]
            },
            parentAuthorityList:[],
            parentAuthorityIDvalue:'',
            editAuthoriryDialogVisible:false,
            editAuthorityForm:{
                id:0,
                name:'',
                abbreviation:'',
                parentID:''
            },
            editAuthorityFormRules:{
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                abbreviation: [
                    { required: true, message: '请输入链接', trigger: 'blur' }
                ]
            },
            Loading:false,
            AdLoading:false,
            EdLoading:false,
            spreadCount:SpreadCount,
        }
    },
    created(){
        this.queryAuthorityList()
    },
    methods:{
        queryAuthorityList(){
            this.queryInfo.start = 1
            this.queryInfo.count = this.spreadCount[0]
            this.GetAuthorityList()
        },
        async GetAuthorityList(){
            this.Loading = true
            const {data:res} = await this.$http.get('Authority/v1/AuthoritiesDicQuery',{params: this.queryInfo})
            this.Loading = false
            if(res.code !== 1){
                return this.$message.error('获取权限列表失败');
            }
            this.authoritylist = res.data.authorities
            this.authoritytotal = res.data.total
        },
        handleSizeChange(count){
            this.queryInfo.count = count
            this.GetAuthorityList()
        },
        handleCurrentChange(start){
            this.queryInfo.start = start
            this.GetAuthorityList()
        },
        AddAuthoriryDialogClosed(){
            this.$refs.addAuthoriryFormRef.resetFields()
        },
        async GetParentAuthoriry(){
            const {data:res} = await this.$http.get('Authority/v1/ParentAuthoriry')
            if(res.code !== 1){
                return this.$message.error('获取父权限列表失败');
            }
            this.parentAuthorityList = res.data
        },
        async ShowAddAuthoriryDialog(){
            this.GetParentAuthoriry()
            this.parentAuthorityIDvalue = ''
            this.addAuthoriryDialogVisible = true
        },
        EditAuthoriryDialogClosed(){
            this.$refs.editAuthoriryFormRef.resetFields()
        },
        ShowModifyAuthority(authority){
            let that = this
            that.editAuthorityForm = authority
            that.GetParentAuthoriry()
            if(that.editAuthorityForm.parentID !== 0){
                that.parentAuthorityIDvalue = that.editAuthorityForm.parentID
            }
            that.editAuthoriryDialogVisible = true
        },
        async DeleteAuthority(authority){
            let that = this
            const confirmResult = await that.$confirm('此操作将永远删除权限, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return that.$message.info('已取消删除')
            }
            that.Loading = true
            await that.$http.delete('Authority/v1/Authority',{params: {id : authority.id}}).
            then(function (response) {
            if(response.data.code != 1)
            {
                that.$message.info('删除失败')
            }
            else
            {
                that.$message.success('删除成功')
            }
            }).catch(function (error) {
                that.Loading = false
                return that.$message.info(error)
            });
            that.Loading = false
            this.GetAuthorityList()
        },
        async AddAuhority(){
            this.$refs.addAuthoriryFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            if(this.parentAuthorityIDvalue === ""){
                this.addAuthoriryForm.ParentID = 0
            }
            else{
                this.addAuthoriryForm.ParentID = parseInt(this.parentAuthorityIDvalue)
            }
            this.AdLoading = true
            let _this =this
            await this.$http.post('Authority/v1/Authority',this.addAuthoriryForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.AdLoading = false
                    return _this.$message.error('添加权限失败')
                }
            }).catch(function (error) {
                _this.AdLoading = false
                return _this.$message.error(error)
            });
            this.AdLoading = false
            this.validret = false
            this.addAuthoriryDialogVisible = false
            this.GetAuthorityList()
            this.$message.success('添加权限成功')
        },
        async ModifyAuthority(){
            this.$refs.editAuthoriryFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            if(this.parentAuthorityIDvalue === ""){
                this.editAuthorityForm.ParentID = 0
            }
            else{
                this.editAuthorityForm.ParentID = parseInt(this.parentAuthorityIDvalue)
            }  
            this.EdLoading = true
            let _this =this
            await this.$http.put('Authority/v1/Authority',this.editAuthorityForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.EdLoading = false
                    return _this.$message.error('修改权限失败')
                }
            }).catch(function (error) {
                _this.EdLoading = false
                return _this.$message.error(error)
            });
            this.EdLoading = false
            this.validret = false
            this.editAuthoriryDialogVisible = false
            this.GetAuthorityList()
            this.$message.success('修改权限成功')
        }
    }
}
</script>

<style  lang="less" scoped>
.treetable{
    margin-top: 15px;
}
.height_div{
        height: 63vh;
        overflow: auto;
    }
</style>